// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/mobileNavLinkbtn/mobileNavlinkBtn.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/mobileNavLinkbtn/mobileNavlinkBtn.tsx");
  import.meta.hot.lastModified = "1730642741826.035";
}
// REMIX HMR END

import { NavLink } from "@remix-run/react";
import { motion } from "framer-motion";
import { fadeIn } from "~/lib/variants";
const MobileNavLink = ({
  children,
  href
}) => {
  return <motion.li variants={fadeIn("right", 0.3, 0.3)} initial="hidden" animate="show" exit="hidden" className="w-full flex items-center justify-center">
      <NavLink title={children?.toString()} to={href} className={({
      isActive,
      isPending
    }) => {
      return `border-primary select-none relative font-medium transition-all duration-150 ring-offset-background rounded-md flex h-10 items-center
              justify-center text-lg py-2 underline-offset-4 mx-4
              after:content-[''] after:absolute after:bottom-0 after:start-0 after:w-full after:h-0.5 after:scale-x-0 after:origin-center
              after:transition-all after:bg-primary after:duration-150 hover:text-primary hover:after:scale-x-100
              ${isActive ? "text-primary after:scale-x-80" : "text-foreground"}
              ${isPending && "text-primary after:scale-x-100"}`;
    }}>
        {children}
      </NavLink>
    </motion.li>;
};
_c = MobileNavLink;
export default MobileNavLink;
var _c;
$RefreshReg$(_c, "MobileNavLink");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;