// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/footer.tsx");
  import.meta.hot.lastModified = "1730642741826.035";
}
// REMIX HMR END

import React from "react";
import { Button } from "../ui/button";
import { Link } from "@remix-run/react";
import { Copyright, Instagram, Send } from "lucide-react";
const Footer = () => {
  return <footer className="flex flex-col items-center justify-center py-4 w-screen mt-12 bg-slate-900/90 shadow-lg shadow-slate-200/80">
      <section className="flex flex-col sm:flex-row flex-wrap lg:justify-around container mx-auto w-full">
        <div className="flex flex-col sm:w-1/2 lg:w-1/4 py-4">
          <strong className="border-b pb-1 text-lg w-full font-medium tracking-tight transition-colors first:mt-0">
            لینک های سریع
          </strong>
          <Link to="/" title="خانه">
            <Button variant="link" name="home">
              خانه
            </Button>
          </Link>
          {/* <Link to="/about" title="درباره ما">
            <Button variant="link" name="home">
              درباره ما
            </Button>
           </Link> */}
          <Link to="/initilize" title="نصب و راه اندازی">
            <Button variant="link" name="services">
              نصب و راه اندازی
            </Button>
          </Link>
          <Link to="/diagnose" title="عیب یابی">
            <Button variant="link" name="services">
              عیب یابی
            </Button>
          </Link>
          <Link to="/charge" title="خرید و شارژ">
            <Button variant="link" name="services">
              خرید و شارژ
            </Button>
          </Link>
          <Link to="/support" title="پشتیبانی">
            <Button variant="link" name="services">
              پشتیبانی
            </Button>
          </Link>
          <Link to="/blog" title="مقالات">
            <Button variant="link" name="services">
              مقالات
            </Button>
          </Link>
        </div>
        <div className="flex flex-col sm:w-1/2 lg:w-1/4 py-4">
          <strong className="border-b pb-1 text-lg w-full font-medium tracking-tight transition-colors first:mt-0">
            شبکه های اجتماعی
          </strong>
          <div className="hidden md:flex w-full py-4 items-center justify-center gap-2">
            <Link to="https://www.instagram.com/XRay.Global/" title="اینستاگرام">
              <Button name="instagram" variant="outline" size="icon" className="hover:bg-primary rounded-full p-3 h-12 w-12">
                <Instagram />
              </Button>
            </Link>
            <Link to="https://t.me/XRayIRSupport" title="تلگرام">
              <Button variant="outline" name="tel number" size="icon" className="hover:bg-primary rounded-full p-3 h-12 w-12">
                <Send />
              </Button>
            </Link>
          </div>
        </div>
      </section>
      <section className="flex md:hidden w-full py-4 items-center justify-center gap-2">
        <Link to="https://www.instagram.com/XRay.Global/" title="اینستاگرام">
          <Button name="instagram" variant="outline" size="icon" className="hover:bg-primary rounded-full p-3 h-12 w-12">
            <Instagram />
          </Button>
        </Link>
        <Link to="https://t.me/XRayIRSupport" title="تلگرام">
          <Button variant="outline" name="tel number" size="icon" className="hover:bg-primary rounded-full p-3 h-12 w-12">
            <Send />
          </Button>
        </Link>
      </section>
      <p className="flex text-sm items-center justify-center">
        <Copyright className="h-4 w-4 " />
        تمامی حقوق مادی و معنوی مربوط به تیم XRay Global میباشد.
      </p>
    </footer>;
};
_c = Footer;
export default Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;