// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/mobileHeader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/mobileHeader.tsx");
  import.meta.hot.lastModified = "1730642741826.035";
}
// REMIX HMR END

import { Link, useLocation } from "@remix-run/react";
import { Button } from "../ui/button";
import { AlignLeft, XIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import MobileNavLink from "../mobileNavLinkbtn/mobileNavlinkBtn";
const MobileHeader = () => {
  _s();
  const [openMenu, setOpenMenu] = useState(false);
  const router = useLocation();
  const MotionClose = motion(() => <XIcon className="w-7 h-7 " />);
  const MotionOpen = motion(() => <AlignLeft className="w-7 h-7 " />);
  useEffect(() => {
    setOpenMenu(false);
  }, [router.pathname]);
  return <header className={`flex w-[calc(100vw-8px)] flex-col md:hidden px-4 mx-1
    ${openMenu ? "h-96" : "h-12 delay-700"} ${openMenu && "divide-y divide-foreground/60"} transition-all duration-300 bg-card/30 backdrop-blur mt-2 fixed z-20 rounded-2xl`}>
      <div className={`flex justify-between items-center w-full my-2 h-8 `}>
        <Link to="/" className="w-32 select-none">
          <img src="/assets/img/xraybox.png" alt="xray global" className="h-10" />
        </Link>
        <Button size="icon" variant="ghost" onClick={() => setOpenMenu(!openMenu)}>
          <AnimatePresence initial={false}>
            {!openMenu ? <MotionOpen initial={{
            opacity: 0
          }} animate={{
            opacity: 1
          }} transition={{
            duration: 300,
            delay: 0.3
          }} exit={{
            opacity: 0,
            animationDuration: "0"
          }} /> : <MotionClose initial={{
            opacity: 0
          }} animate={{
            opacity: 1
          }} transition={{
            duration: 300,
            delay: 0.3
          }} exit={{
            opacity: 0,
            animationDuration: "0"
          }} />}
          </AnimatePresence>
        </Button>
      </div>
      <AnimatePresence>
        {openMenu && <>
            <nav className="list-none pt-2">
              <MobileNavLink href="/">خانه</MobileNavLink>
              {/* <MobileNavLink href="/about">درباره ما</MobileNavLink> */}
              <MobileNavLink href="/initilize">نصب و راه اندازی</MobileNavLink>
              <MobileNavLink href="/diagnose">عیب یابی</MobileNavLink>
              <MobileNavLink href="/charge">خرید و شارژ</MobileNavLink>
              <MobileNavLink href="/support">پشتیبانی</MobileNavLink>
              <MobileNavLink href="/blog">مقالات</MobileNavLink>
            </nav>
            {/* <nav className="list-none flex gap-x-2 w-full justify-center items-center pt-2">
              <motion.li
                variants={fadeIn("left", 0.3, 0.5)}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Link title="ثبت نام" to="/signup">
                  <Button variant="default" className="text-white">
                    ثبت نام
                  </Button>
                </Link>
              </motion.li>
              <motion.li
                variants={fadeIn("right", 0.3, 0.5)}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Link title="ورود" to="/login">
                  <Button variant="outline">ورود</Button>
                </Link>
              </motion.li>
             </nav> */}
          </>}
      </AnimatePresence>
    </header>;
};
_s(MobileHeader, "jr/0ksZSr1MCOviym/Mpu9fgHz8=", false, function () {
  return [useLocation];
});
_c = MobileHeader;
export default MobileHeader;
var _c;
$RefreshReg$(_c, "MobileHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;