// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/index.tsx");
  import.meta.hot.lastModified = "1730642741826.035";
}
// REMIX HMR END

import Header from "./header";
import MobileHeader from "./mobileHeader";
import Footer from "./footer";
import ParticlesContainer from "../particlesContainer/particlesContainer";
import { motion } from "framer-motion";
const Layout = ({
  children
}) => {
  return <main className="">
      <Header />
      <MobileHeader />
      <div className="max-w-full min-h-screen">
        <ParticlesContainer />
        {/* pulse xray logo animation */}
        <div className="fixed left-0 right-0 h-screen md:h-auto bottom-auto md:bottom-4 w-screen flex flex-col justify-center items-center md:justify-between p-8 md:flex-row gap-y-4 -z-20">
          {/* <motion.img
            initial={{ opacity: 0, scale: 0 }}
            transition={{ duration: 0.5, delay: 2.3 }}
            animate={{ opacity: 1, scale: 1 }}
            src="/assets/img/xrayglobal.png"
            alt="xray global"
            className="h-16 md:h-20 animate-pulse"
           /> */}
          <motion.img initial={{
          opacity: 0,
          scale: 0
        }} transition={{
          duration: 0.5,
          delay: 2.3
        }} animate={{
          opacity: 1,
          scale: 1
        }} src="/assets/img/xraybox.png" alt="xray box" className="h-16 md:h-20 animate-pulse" />
        </div>
        {children}
      </div>
      <Footer />
    </main>;
};
_c = Layout;
export default Layout;
var _c;
$RefreshReg$(_c, "Layout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;