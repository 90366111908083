// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/particlesContainer/particlesContainer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/particlesContainer/particlesContainer.tsx");
  import.meta.hot.lastModified = "1730642741826.035";
}
// REMIX HMR END

import { tsParticles } from "@tsparticles/engine";
import { loadPolygonMaskPlugin } from "@tsparticles/plugin-polygon-mask";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { loadFull } from "tsparticles";
const ParticlesContainer = () => {
  _s();
  const [init, setInit] = useState(false);

  // const options = useMemo(
  //   () => ({
  //     pauseOnBlur: false,
  //     interactivity: {
  //       events: {
  //         onHover: {
  //           enable: true,
  //           mode: "bubble"
  //         },
  //         resize: true
  //       },
  //       modes: {
  //         bubble: {
  //           distance: 40,
  //           duration: 2,
  //           opacity: 8,
  //           size: 6,
  //           speed: 3
  //         }
  //       }
  //     },
  //     particles: {
  //       color: {
  //         value: ["#4285f4", "#34A853", "#FBBC05", "#EA4335"]
  //       },
  //       links: {
  //         color: "random",
  //         distance: 40,
  //         enable: true,
  //         opacity: 0.8,
  //         width: 1
  //       },
  //       move: {
  //         direction: "none",
  //         enable: true,
  //         outModes: "bounce",
  //         speed: 1
  //       },
  //       number: {
  //         value: 450
  //       },
  //       opacity: {
  //         animation: {
  //           enable: true,
  //           speed: 2,
  //           sync: false
  //         },
  //         value: { min: 0.3, max: 0.8 }
  //       },
  //       shape: {
  //         type: "circle"
  //       },
  //       size: {
  //         value: 1
  //       }
  //     },
  //     polygon: {
  //       draw: {
  //         enable: true,
  //         stroke: {
  //           color: "#fff",
  //           opacity: 0.2,
  //           width: 1
  //         }
  //       },
  //       enable: true,
  //       move: {
  //         radius: 5
  //       },
  //       position: {
  //         x: 50,
  //         y: 50
  //       },
  //       inline: {
  //         arrangement: "equidistant"
  //       },
  //       scale: 1,
  //       type: "inline",
  //       url: "/assets/img/xrayglobal.svg"
  //     },
  //     background: {
  //       color: "",
  //       image: "",
  //       position: "50% 50%",
  //       repeat: "no-repeat",
  //       size: "cover"
  //     }
  //   }),
  //   []
  // );

  const options = useMemo(() => ({
    fullScreen: {
      enable: false
    },
    background: {
      color: {
        value: ""
      }
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push"
        },
        onHover: {
          enable: true,
          mode: "grab",
          parallax: {
            enable: true,
            force: 60,
            smooth: 10
          }
        },
        resize: true
      },
      modes: {
        push: {
          quantity: 3
        },
        repulse: {
          distance: 60,
          duration: 0.3
        },
        grab: {
          distance: 200,
          links: {
            blink: false,
            consent: false,
            opacity: 1
          }
        }
      }
    },
    particles: {
      color: {
        value: "#fafafa"
      },
      links: {
        color: "#ddd",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1
      },
      collisions: {
        enable: true
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce"
        },
        random: false,
        speed: 1,
        straight: false
      },
      number: {
        density: {
          enable: true,
          area: 800
        },
        value: 120
      },
      opacity: {
        value: 0.5
      },
      shape: {
        type: "circle"
      },
      size: {
        value: {
          min: 1,
          max: 5
        }
      }
    },
    detectRetina: true
  }), []);
  useEffect(() => {
    initParticlesEngine(async engine => {
      await loadPolygonMaskPlugin(tsParticles);
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);
  const particlesLoaded = useCallback(async container => {
    console.log(container);
  }, []);
  return <div className="w-screen h-screen fixed -z-10 bg-black/50">
      {init && <Particles className="w-screen h-screen fixed -z-10" id="tsparticles" options={options} particlesLoaded={particlesLoaded} />}
    </div>;
};
_s(ParticlesContainer, "sy/Wskmonaobj3UuUtrLkjdUk5s=");
_c = ParticlesContainer;
export default ParticlesContainer;
var _c;
$RefreshReg$(_c, "ParticlesContainer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;