// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/header.tsx");
  import.meta.hot.lastModified = "1730642741826.035";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import NavLinkBtn from "../navLinkBtn/navLinkBtn";
const Header = () => {
  return <header className="hidden md:flex justify-start px-4">
      <div className="fixed flex gap-x-12 justify-center px-8 items-center z-20 rounded-3xl bg-card/30 backdrop-blur gap-2 py-2 h-14 2xl:h-16 mx-auto mt-2 ">
        <Link to="/" className="w-32 select-none">
          <img src="/assets/img/xraybox.png" alt="xray global" className="h-11" />
        </Link>
        <nav className="list-none flex items-center justify-center ">
          <NavLinkBtn href="/">خانه</NavLinkBtn>
          {/* <NavLinkBtn href="/about">درباره ما</NavLinkBtn> */}
          <NavLinkBtn href="/initilize">نصب و راه اندازی</NavLinkBtn>
          <NavLinkBtn href="/diagnose">عیب یابی</NavLinkBtn>
          <NavLinkBtn href="/charge">خرید و شارژ</NavLinkBtn>
          <NavLinkBtn href="/support">پشتیبانی</NavLinkBtn>
          <NavLinkBtn href="/blog">مقالات</NavLinkBtn>
        </nav>
      </div>
      {/* <div className="flex items-center gap-2 w-32 select-none">
        <Link title="ثبت نام" to="/signup">
          <Button variant="default" size="sm" className="my-4 text-white">
            ثبت نام
          </Button>
        </Link>
        <Link title="ورود" to="/login">
          <Button variant="outline" size="sm" className="my-4">
            ورود
          </Button>
        </Link>
       </div> */}
    </header>;
};
_c = Header;
export default Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;